import React from "react"
import { Link, navigate } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import newsStyles from "../styles/pages/news.module.css"
import { FaChevronCircleRight } from 'react-icons/fa';

import awarding from "../images/news/article-1/awarding.jpg"
import edad from "../images/news/article-2/ED AD2.png"
import setup1 from "../images/news/article-3/pict of xowii 1.png"
import smw1 from "../images/news/article-4/TED7.jpg"
import corporate1 from "../images/news/article-5/TED1.jpg"

const NewsPage = () => {
    return (
      <Layout location="news">
        <SEO title="News" />
        <div className={ newsStyles.withPosts }>
          <div className={ newsStyles.container }>
            <h1 className={ newsStyles.title }>News</h1>
            <div className={ newsStyles.content }>
              <h4>5 Posts</h4>
              
              <div className={ newsStyles.card }>
                <div className={ newsStyles.imgWrapper }>
                  <img src={ corporate1 } alt=""/>
                </div>
                <div className={ newsStyles.cardText }>
                  <h3>CORPORATE EVENTS</h3>
                  <h5>November 26, 2020</h5>
                  <p>TeamED is a reliable name in the corporate scene, for both business and social events of companies big and small, local and foreign, MNCs and SMEs, providing enterprises...</p>
                  <Link to="/news/article-5/"><span>Continue Reading</span> <FaChevronCircleRight /></Link>
                </div>
              </div>
              <div className={ newsStyles.card }>
                <div className={ newsStyles.imgWrapper }>
                  <img src={ smw1 } alt=""/>
                </div>
                <div className={ newsStyles.cardText }>
                  <h3>Seminars, Meetings, Workshops</h3>
                  <h5>November 26, 2020</h5>
                  <p>With over two decades of experience, countless seminars, meetings, workshops and special presentations have already...</p>
                  <Link to="/news/article-4/"><span>Continue Reading</span> <FaChevronCircleRight /></Link>
                </div>
              </div>
              <div className={ newsStyles.card }>
                <div className={ newsStyles.imgWrapper }>
                  <img src={ setup1 } alt=""/>
                </div>
                <div className={ newsStyles.cardText }>
                  <h3>SAMPLE STANDARD SET-UPS</h3>
                  <h5>November 26, 2020</h5>
                  <p>Above pics are standard set-ups for conferences. Special events specialist Team ED provides standard set-ups for quick mounts of events when clients such as organizers and companies find...</p>
                  <Link to="/news/article-3/"><span>Continue Reading</span> <FaChevronCircleRight /></Link>
                </div>
              </div>
              <div className={ newsStyles.card }>
                <div className={ newsStyles.imgPoster }>
                  <img src={ edad } alt=""/>
                </div>
                <div className={ newsStyles.cardText }>
                  <h3>TEAM ED is part of EDIMCF Corp.</h3>
                  <h5>November 26, 2020</h5>
                  <p>An integrated marketing communications organization that provides marcom services to clients of all sizes, from different industries, to effectively...</p>
                  <Link to="/news/article-2/"><span>Continue Reading</span> <FaChevronCircleRight /></Link>
                </div>
              </div>
              <div className={ newsStyles.card }>
                <div className={ newsStyles.imgWrapper }>
                  <img src={ awarding }  alt=""/>
                </div>
                <div className={ newsStyles.cardText }>
                  <h3>SPECIAL TANGLAW AWARD</h3>
                  <h5>November 26, 2020</h5>
                  <p>The special Gawad Tanglaw award by the Ad Foundation of the Philippines being presented to the legendary advertising honcho JJ Calero during the 10th Araw Values Awards...</p>
                  <Link to="/news/article-1/"><span>Continue Reading</span> <FaChevronCircleRight /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );

    // For when there are no articles, replace the code above with this (uncommented, of course):
    // return (
    //   <Layout location="news">
    //     <SEO title="News" />
    //     <div className={ newsStyles.noPosts }>
    //       <div className={ newsStyles.container }>
    //         <h1 className={ newsStyles.title }>News</h1>
    //         <div className={ newsStyles.content }>
    //           <div className={ newsStyles.card }>
    //             <h5>There are no new posts.</h5>
    //             <p>Check back again later for updates on any events we're holding.</p>
    //             <button onClick={() => {
    //                 window.history.back()
    //             }}>Go Back</button>  
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </Layout>
    // );
}

export default NewsPage